import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext, useField } from 'formik'

// Components
import Textarea from '@/basicComponents/Textarea'
import ErrorMessage from '../ErrorMessage'

// Variables / Functions
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  wrapperClassName: PropTypes.string,
  style: PropTypes.object,
}

export const defaultProps = {}

function TextareaField(props) {
  const { name, size, style, wrapperClassName, ...restProps } = props
  const [field] = useField(props)
  const { setFieldValue } = useFormikContext()

  const onChange = (event) => {
    const value = event.target.value

    setFieldValue(field.name, value)
  }

  return (
    <div className={cx('textarea-field-wrapper', wrapperClassName)}>
      <Textarea {...restProps} onChange={onChange} value={field.value || ''} />
      <ErrorMessage name={name} size={size} />
    </div>
  )
}

TextareaField.propTypes = propTypes
TextareaField.defaultProps = defaultProps

export default TextareaField
