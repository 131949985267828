import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext, useField } from 'formik'

// Components
import ColorInput from '@/basicComponents/ColorInput'
// import ErrorMessage from '../ErrorMessage'

// Variables / Functions
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export const defaultProps = { onChange: () => {} }

function ColorInputField(props) {
  const { className, name, onChange: onChangeProps, ...restProps } = props
  const [field] = useField(props)
  const { setFieldValue } = useFormikContext()

  const onChange = (color) => {
    setFieldValue(field.name, color)
    onChangeProps(color)
  }

  return (
    <div className={cx('color-input-field-wrapper', className)}>
      <ColorInput {...restProps} onChange={onChange} value={field.value} defaultValue={field.value} name={name} />
    </div>
  )
}

ColorInputField.propTypes = propTypes
ColorInputField.defaultProps = defaultProps

export default ColorInputField
