import React from 'react'

// Components
import Home from '@/views/Home'

// Lib MISC

function App() {
  return <Home />
}

export default App
