import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext } from 'formik'

// Components
import DeviceItem from './components/DeviceItem'
import Card from './components/Card'
import Empty from './components/Empty'
import DesktopIcon from '@/assets/svg/form-desktop.svg'
import DesktopActivedIcon from '@/assets/svg/form-desktop-active.svg'
import MobileIcon from '@/assets/svg/form-mobile.svg'
import MobileActivedIcon from '@/assets/svg/form-mobile-active.svg'

// Lib MISC
import { DEVICE_MODE } from '@/constants/deviceMode'

import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  isDesktopMode: PropTypes.bool,
  isMobileMode: PropTypes.bool,
  handleDeviceClick: PropTypes.func,
}

function Display(props) {
  const { isDesktopMode, isMobileMode, handleDeviceClick } = props

  const { dirty } = useFormikContext()

  return (
    <div className={cx('display')}>
      <div className={cx('display-container')}>
        <div className={cx('display-container-wrapper')}>
          <div className={cx('display-container-wrapper__device')}>
            <p className={cx('display-container-wrapper__device-title')}>Toggle Device</p>
            <div className={cx('display-container-wrapper__device-button')}>
              <DeviceItem
                icon={DesktopIcon}
                activedIcon={DesktopActivedIcon}
                isActived={isDesktopMode}
                handleDeviceClick={() => handleDeviceClick(DEVICE_MODE.DESKTOP)}
                title='Desktop'
              />
              <DeviceItem
                icon={MobileIcon}
                activedIcon={MobileActivedIcon}
                isActived={isMobileMode}
                handleDeviceClick={() => handleDeviceClick(DEVICE_MODE.MOBILE)}
                title='Mobile'
              />
            </div>
          </div>
          {dirty && <Card isMobileMode={isMobileMode} />}
          {!dirty && <Empty />}
        </div>
      </div>
    </div>
  )
}

Display.propTypes = propTypes

export default Display
