import { ReactComponent as Address } from '@/assets/svg/address.svg'
import { ReactComponent as Email } from '@/assets/svg/email.svg'
import { ReactComponent as Fax } from '@/assets/svg/fax.svg'
import { ReactComponent as Phone } from '@/assets/svg/phone.svg'
import { ReactComponent as Web } from '@/assets/svg/web.svg'
import { ReactComponent as Linkedin } from '@/assets/svg/linkedin.svg'
import { ReactComponent as Facebook } from '@/assets/svg/facebook.svg'
import { ReactComponent as Twitter } from '@/assets/svg/twitter.svg'
import { ReactComponent as Whatsapp } from '@/assets/svg/whatsapp.svg'
import { ReactComponent as Wechat } from '@/assets/svg/wechat.svg'
import { ReactComponent as AddContact } from '@/assets/svg/add-contact.svg'
import { ReactComponent as Contact } from '@/assets/svg/contact.svg'
import { ReactComponent as QrCode } from '@/assets/svg/qr-code.svg'
import { ReactComponent as Success } from '@/assets/svg/success.svg'

export default {
  Address,
  Email,
  Fax,
  Phone,
  Web,
  Linkedin,
  Facebook,
  Twitter,
  Whatsapp,
  Wechat,
  AddContact,
  Contact,
  QrCode,
  Success,
}
