import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import Form from '@/basicComponents/Form'
import RemoveIcon from '@/assets/svg/remove.svg'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  index: PropTypes.number,
  arrayHelpers: PropTypes.object,
}

function PhoneItem(props) {
  const { index, arrayHelpers } = props

  const phoneTypeOptions = [
    { label: 'Mobile', value: 'MOBILE' },
    { label: 'Fax', value: 'FAX' },
    { label: 'Work', value: 'WORK' },
  ]

  const fieldName = 'contact.phoneList'

  return (
    <div className={cx('phone-item')}>
      <Form.InputField className={cx('phone-item-input')} name={`${fieldName}[${index}].number`} placeholder='Phone' elementType='tel' autoFocus />
      <Form.SelectField
        className={cx('phone-item-select')}
        name={`${fieldName}[${index}].type`}
        options={phoneTypeOptions}
        defaultValue={phoneTypeOptions[0]}
      />
      <div className={cx('phone-item-remove')}>
        <img className={cx('phone-item-remove-icon')} src={RemoveIcon} onClick={() => arrayHelpers.remove(index)} />
      </div>
    </div>
  )
}

PhoneItem.propTypes = propTypes

export default PhoneItem
