import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import DoneIcon from '@/assets/svg/step-done.svg'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  stepLength: PropTypes.number,
  isProcessing: PropTypes.bool,
  isDoned: PropTypes.bool,
  order: PropTypes.number,
  text: PropTypes.string,
}

function Step(props) {
  const { stepLength, isProcessing, isDoned, order, text } = props

  const isLastStep = order === stepLength - 1

  return (
    <div className={cx('step')} data-has-next={!isLastStep}>
      <div className={cx('step-order')}>
        <div className={cx('step-order-wrapper')}>
          {!isDoned && (
            <p className={cx('step-order-wrapper-number')} data-is-processing={isProcessing}>
              {order + 1}
            </p>
          )}
          {isDoned && <img src={DoneIcon} />}
        </div>
        {!isLastStep && <div className={cx('step-order-line')} data-is-doned={isDoned} />}
      </div>
      <p className={cx('step-text')}>{text}</p>
    </div>
  )
}

Step.propTypes = propTypes

export default Step
