import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'dark']),
  elementType: PropTypes.string,
}

const defaultProps = {
  type: 'primary',
}

function Input(props) {
  const { className, type, elementType, ...restProps } = props

  return <input className={cx('input', className)} data-type={type} type={elementType} {...restProps} />
}

Input.propTypes = propTypes
Input.defaultProps = defaultProps

export default Input
