import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import Lottie from 'lottie-react'

// Components
import Button from '@/basicComponents/Button'
import Step from './components/Step'
import PersonalInfo from './components/PersonalInfo'
import ContactInfo from './components/ContactInfo'
import SocialLink from './components/SocialLink'
import CardStyle from './components/CardStyle'
import Footer from '@/basicComponents/Footer'
import Modal from '@/basicComponents/Modal'
import BackIcon from '@/assets/svg/arrow-back.svg'
import Checkbox from '@/basicComponents/Checkbox'
import Icons from '@/assets/icons'

// Lib MISC
import animationData from '@/assets/lotties/recards_loading_create.json'

import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  isSubmmittedSuccessfully: PropTypes.bool,
}

const PROCEDURE_TYPE = {
  PERSONAL_INFO: 0,
  CONTACT_INFO: 1,
  SOCIAL_LINK: 2,
  CARD_STYLE: 3,
}

const PROCEDURE_LIST = [
  { order: 0, text: 'Personal Info' },
  { order: 1, text: 'Contact Info' },
  { order: 2, text: 'Social Link' },
  { order: 3, text: 'Card Style' },
]

const MODAL_MESSAGE = {
  LEAVE: {
    title: 'Leave this page?',
    content: 'Are you sure you want to leave this page? All information you filled will not be saved.',
    confirmLabel: 'No, stay creating',
    cancelLabel: 'Leave',
  },
  CANCEL: {
    title: 'Cancel creating?',
    content: 'Are you sure you want to cancel creating your ReCards? All information you filled will not be saved.',
    confirmLabel: 'No, stay creating',
    cancelLabel: 'Yes, cancel',
  },
}

function Action(props) {
  const { currentPage, setCurrentPage, data, isLoading, isSubmmittedSuccessfully } = props

  const { createNameCard: cardId } = data

  const [isLeaving, setIsLeaving] = useState(false)
  const [isAgree, setIsAgree] = useState(false)
  const [modalContent, setModalContent] = useState({})

  const history = useHistory()

  const isFirstPage = currentPage === 0
  const isLastPage = currentPage === PROCEDURE_LIST.length - 1

  const { validateForm, submitForm } = useFormikContext()

  const onBackClick = () => {
    setCurrentPage((prev) => prev - 1)

    history.replace(`/?page=step${currentPage}`)
  }

  const handleFormSubmit = () => {
    validateForm().then((error) => {
      submitForm().then(() => {
        if (isEmpty(error) && !isLastPage) {
          setCurrentPage((prev) => prev + 1)

          const currentPageNumber = currentPage + 1
          const nextPageNumber = currentPageNumber + 1

          history.replace(`/?page=step${nextPageNumber}`)
        }
      })
    })
  }

  const handleModalOpen = (type) => {
    setIsLeaving(true)
    setModalContent(MODAL_MESSAGE[type])
  }

  const handleLeaveClick = () => {
    window.location.href = 'https://www.re.cards/'
  }

  const handleCheckCard = () => {
    window.open(`https://get.re.cards/${cardId}/`, '_blank')
  }

  return (
    <div className={cx('action')}>
      <div className={cx('action-container')}>
        <div className={cx('action-container-wrapper')}>
          <div className={cx('action-container-wrapper-back')} onClick={() => handleModalOpen('LEAVE')}>
            <div className={cx('action-container-wrapper-back-icon')}>
              <img className={cx('action-container-wrapper-back-icon-image')} src={BackIcon} />
            </div>
            <p className={cx('action-container-wrapper-back-text')}>Back to homepage</p>
          </div>
          <div className={cx('action-container-wrapper-content')}>
            <p className={cx('action-container-wrapper-content-title')}>Create your ReCards</p>
            <div className={cx('action-container-wrapper-content-procedure')}>
              {PROCEDURE_LIST.map((item, index) => (
                <Step key={index} stepLength={PROCEDURE_LIST.length} isProcessing={currentPage === index} isDoned={currentPage > index} {...item} />
              ))}
            </div>

            {currentPage === PROCEDURE_TYPE.PERSONAL_INFO && <PersonalInfo />}
            {currentPage === PROCEDURE_TYPE.CONTACT_INFO && <ContactInfo />}
            {currentPage === PROCEDURE_TYPE.SOCIAL_LINK && <SocialLink />}
            {currentPage === PROCEDURE_TYPE.CARD_STYLE && <CardStyle />}

            {isLastPage && (
              <div className={cx('action-container-wrapper-content-agree')}>
                <Checkbox checked={isAgree} onChange={() => setIsAgree((prev) => !prev)} />
                <div className={cx('action-container-wrapper-content-agree-wrapper')}>
                  <span className={cx('action-container-wrapper-content-agree-wrapper-text')}>By creating ReCards, you agree to the </span>
                  <a href='https://www.re.cards/tnc' rel='noreferrer noopener' target='_blank'>
                    <span className={cx('action-container-wrapper-content-agree-wrapper-text', 'highlight')}>Terms and Conditions</span>
                  </a>
                  <span className={cx('action-container-wrapper-content-agree-wrapper-text')}> and </span>
                  <a href='https://www.re.cards/privacy-policy' rel='noreferrer noopener' target='_blank'>
                    <span className={cx('action-container-wrapper-content-agree-wrapper-text', 'highlight')}>Privacy Policy</span>
                  </a>
                  <span className={cx('action-container-wrapper-content-agree-wrapper-text')}>.</span>
                </div>
              </div>
            )}

            <div className={cx('action-container-wrapper-content-actions')}>
              {isFirstPage && (
                <div className={cx('action-container-wrapper-content-actions-comment')}>
                  <p className={cx('action-container-wrapper-content-actions-comment-marker')}>*</p>
                  <p className={cx('action-container-wrapper-content-actions-comment-text')}>Required fields</p>
                </div>
              )}

              <div className={cx('action-container-wrapper-content-actions-button')}>
                <div className={cx('action-container-wrapper-content-actions-button-item')} data-is-grow={!isFirstPage}>
                  <Button isFilled={false} onClick={() => handleModalOpen('CANCEL')}>
                    Cancel
                  </Button>
                </div>
                {!isFirstPage && (
                  <Button className={cx('action-container-wrapper-content-actions-button-item')} onClick={onBackClick} isFilled={false}>
                    Back
                  </Button>
                )}
                {!isLastPage && (
                  <Button className={cx('action-container-wrapper-content-actions-button-item')} onClick={handleFormSubmit}>
                    Next step
                  </Button>
                )}
                {isLastPage && (
                  <Button className={cx('action-container-wrapper-content-actions-button-item')} onClick={handleFormSubmit} disabled={!isAgree}>
                    Create Recards
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {isLeaving && <Modal onConfirm={() => setIsLeaving(false)} {...modalContent} onCancel={handleLeaveClick} />}
      {isLoading && (
        <Modal className={cx('action-modal')} hasFooter={false} bodyStyle={{ alignItems: 'center', paddingTop: '0px' }}>
          <Lottie className={cx('action-modal-lottie')} animationData={animationData} width={120} height={140} />
          <p className={cx('action-modal-title', 'loading')}>Creating your ReCards…</p>
          <p className={cx('action-modal-content')}>We are now creating your ReCards. Please do not close the page.</p>
        </Modal>
      )}
      {isSubmmittedSuccessfully && (
        <Modal
          className={cx('action-modal')}
          hasHeader={false}
          bodyStyle={{ alignItems: 'center', marginBottom: '40px', paddingTop: '0px' }}
          confirmLabel='Check My ReCards'
          cancelLabel='Back'
          onCancel={handleLeaveClick}
          onConfirm={handleCheckCard}
        >
          <Icons.Success className={cx('action-modal-icon')} />
          <p className={cx('action-modal-title')}>ReCards Created!</p>
          <p className={cx('action-modal-content')}>Your personal ReCards has been created. Check My ReCards Now!</p>
        </Modal>
      )}
    </div>
  )
}

Action.propTypes = propTypes

export default Action
