import React, { useRef } from 'react'
// import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext } from 'formik'

// Components
import Button from '@/basicComponents/Button'
import ProfileIcon from '@/assets/images/profile-photo.png'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

function PhotoUpload(props) {
  const inputRef = useRef(null)

  const { values, setFieldValue } = useFormikContext()
  const { personal } = values
  const { photo } = personal

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]

    if (!selectedFile) return

    const fileUrl = window.URL.createObjectURL(selectedFile)

    setFieldValue('personal.photo', { file: selectedFile, fileUrl })
  }

  return (
    <div className={cx('photo-upload')}>
      <div className={cx('photo-upload-display')}>
        <img className={cx('photo-upload-display-image')} src={photo?.fileUrl || ProfileIcon} />
      </div>
      <input className={cx('photo-upload-input')} ref={inputRef} type='file' accept='image/*' onChange={handleFileChange} />
      <Button className={cx('photo-upload-button')} size='xs' onClick={() => inputRef.current.click()}>
        Upload profile photo
      </Button>
    </div>
  )
}

PhotoUpload.propTypes = propTypes

export default PhotoUpload
