// import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { FieldArray, useFormikContext } from 'formik'

// Components
import SectionTitle from '../SectionTitle'
import Form from '@/basicComponents/Form'
import PhoneItem from './components/PhoneItem'
import AddIcon from '@/assets/svg/add.svg'
import RemoveIcon from '@/assets/svg/remove.svg'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

const defaultPhoneItem = { number: '', type: 'MOBILE' }

function ContactInfo(props) {
  const { values } = useFormikContext()
  const { contact } = values
  const { phoneList, emailList, webUrlList } = contact

  return (
    <div className={cx('contact-info')}>
      <SectionTitle title='CONTACT INFORMATION' />
      <div className={cx('contact-info')}>
        <div className={cx('contact-info-row')}>
          <p className={cx('contact-info-row-title')}>Phone</p>

          <FieldArray
            name='contact.phoneList'
            render={(arrayHelpers) => (
              <>
                {phoneList.map((phone, index) => (
                  <PhoneItem key={index} index={index} arrayHelpers={arrayHelpers} {...phone} />
                ))}
                <div className={cx('contact-info-row-add')} onClick={() => arrayHelpers.push(defaultPhoneItem)}>
                  <img className={cx('contact-info-row-add-icon')} src={AddIcon} />
                  <p className={cx('contact-info-row-add-text')}>Add phone number</p>
                </div>
              </>
            )}
          />
        </div>

        <div className={cx('contact-info-row')}>
          <p className={cx('contact-info-row-title')}>Email Address</p>
          <FieldArray
            name='contact.emailList'
            render={(arrayHelpers) => (
              <>
                {emailList.map((phone, index) => (
                  <div className={cx('contact-info-row-item')} key={index}>
                    <Form.InputField
                      className={cx('contact-info-row-item-input')}
                      name={`contact.emailList[${index}]`}
                      placeholder='Email address'
                      elementType='email'
                      autoFocus
                    />
                    <div className={cx('contact-info-row-item-remove')}>
                      <img className={cx('contact-info-row-item-remove-icon')} src={RemoveIcon} onClick={() => arrayHelpers.remove(index)} />
                    </div>
                  </div>
                ))}
                <div className={cx('contact-info-row-add')} onClick={() => arrayHelpers.push('')}>
                  <img className={cx('contact-info-row-add-icon')} src={AddIcon} />
                  <p className={cx('contact-info-row-add-text')}>Add email address</p>
                </div>
              </>
            )}
          />
        </div>

        <div className={cx('contact-info-row')}>
          <p className={cx('contact-info-row-title')}>Website URL</p>
          <FieldArray
            name='contact.webUrlList'
            render={(arrayHelpers) => (
              <>
                {webUrlList.map((phone, index) => (
                  <div className={cx('contact-info-row-item')} key={index}>
                    <Form.InputField
                      className={cx('contact-info-row-item-input')}
                      name={`contact.webUrlList[${index}]`}
                      placeholder='Website URL'
                      elementType='url'
                      autoFocus
                    />
                    <div className={cx('contact-info-row-item-remove')}>
                      <img className={cx('contact-info-row-item-remove-icon')} src={RemoveIcon} onClick={() => arrayHelpers.remove(index)} />
                    </div>
                  </div>
                ))}
                <div className={cx('contact-info-row-add')} onClick={() => arrayHelpers.push('')}>
                  <img className={cx('contact-info-row-add-icon')} src={AddIcon} />
                  <p className={cx('contact-info-row-add-text')}>Add website URL</p>
                </div>
              </>
            )}
          />
        </div>

        <div className={cx('contact-info-row')}>
          <p className={cx('contact-info-row-title')}>Company address</p>
          <Form.InputField className={cx('contact-info-row-input')} name='contact.address' placeholder='Company address' />
          <p className={cx('contact-info-row-title')}>Company address in Chinese</p>
          <Form.InputField className={cx('contact-info-row-input')} name='contact.chineseAddress' placeholder='Company address' />
        </div>
      </div>
    </div>
  )
}

ContactInfo.propTypes = propTypes

export default ContactInfo
