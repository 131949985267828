import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext } from 'formik'

// Components
import Icons from '@/assets/icons'
import ProfileIcon from '@/assets/images/profile-photo.png'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  isMobileMode: PropTypes.bool,
}

function Card(props) {
  const { isMobileMode } = props

  const { values } = useFormikContext()
  const { personal, contact, social, cardStyle } = values
  const { photo, lastName, firstName, chineseName, title, chineseTitle, company, companyLogo } = personal
  const { phoneList, emailList, webUrlList, address, chineseAddress } = contact
  const { linkedIn, facebook, twitter, whatsapp, wechat } = social
  const {
    headerStartBackgroundColor,
    headerEndBackgroundColor,
    footerBackgroundColor,
    cardBackgroundColor,
    textColor,
    iconColor,
    buttonColor,
    headerImage,
    footerImage,
    mobileHeaderImage,
    mobileFooterImage,
  } = cardStyle

  const backgroundLinear = `linear-gradient(45deg, ${headerStartBackgroundColor} 0%, ${headerStartBackgroundColor} 60%, ${headerEndBackgroundColor} 60%, ${headerEndBackgroundColor} 100%)`
  const headerBackgroundImage = isMobileMode ? mobileHeaderImage?.fileUrl : headerImage?.fileUrl
  const footerBackgroundImage = isMobileMode ? mobileFooterImage?.fileUrl : footerImage?.fileUrl

  return (
    <div
      className={cx('card')}
      style={{
        color: textColor,
        backgroundColor: footerBackgroundColor,
      }}
      data-is-mobile={isMobileMode}
    >
      <div className={cx('card-header')} data-is-mobile={isMobileMode} style={{ background: backgroundLinear }}>
        <img className={cx('card-header-image')} src={headerBackgroundImage} />
      </div>
      <img className={cx('card-footer-image')} src={footerBackgroundImage} />
      <div className={cx('card-wrapper')} style={{ backgroundColor: cardBackgroundColor }} data-is-mobile={isMobileMode}>
        <div className={cx('card-wrapper__left')}>
          <div className={cx('card-wrapper__left-info')} data-is-mobile={isMobileMode}>
            <div className={cx('card-wrapper__left-info-photo')} data-is-mobile={isMobileMode}>
              <img className={cx('card-wrapper__left-info-photo-image')} src={photo?.fileUrl || ProfileIcon} />
            </div>
            <div className={cx('card-wrapper__left-info-name')} data-is-mobile={isMobileMode}>
              <div className={cx('card-wrapper__left-info-name-wrapper')} data-is-mobile={isMobileMode}>
                <div className={cx('card-wrapper__left-info-name-wrapper-english')}>
                  <p className={cx('card-wrapper__left-info-name-wrapper-english-text')} data-is-mobile={isMobileMode}>
                    {`${firstName} ${lastName}`}
                  </p>
                </div>
                <p className={cx('card-wrapper__left-info-name-wrapper-chinese')} data-is-mobile={isMobileMode}>
                  {chineseName}
                </p>
              </div>
              <div className={cx('card-wrapper__left-info-name-social')} data-is-mobile={isMobileMode}>
                {linkedIn.value && <Icons.Linkedin className={cx('card-wrapper__left-info-name-social-icon')} data-is-mobile={isMobileMode} />}
                {facebook.value && <Icons.Facebook className={cx('card-wrapper__left-info-name-social-icon')} data-is-mobile={isMobileMode} />}
                {twitter.value && <Icons.Twitter className={cx('card-wrapper__left-info-name-social-icon')} data-is-mobile={isMobileMode} />}
                {whatsapp.value && <Icons.Whatsapp className={cx('card-wrapper__left-info-name-social-icon')} data-is-mobile={isMobileMode} />}
              </div>
            </div>
          </div>

          <div className={cx('card-wrapper__left-title')} data-is-mobile={isMobileMode}>
            <p className={cx('card-wrapper__left-title-english')}>{title}</p>
            <p className={cx('card-wrapper__left-title-chinese')}>{chineseTitle}</p>
          </div>

          <p className={cx('card-wrapper__left-company')} data-is-mobile={isMobileMode}>
            {company}
          </p>

          <div className={cx('card-wrapper__left-contact')} data-is-mobile={isMobileMode}>
            {phoneList.map((phone, index) => (
              <div className={cx('card-wrapper__left-contact-wrapper')} key={index} data-is-mobile={isMobileMode}>
                <div>
                  {phone.type !== 'FAX' && (
                    <Icons.Phone
                      className={cx('card-wrapper__left-contact-wrapper-icon')}
                      style={{ fill: iconColor }}
                      data-is-mobile={isMobileMode}
                    />
                  )}
                  {phone.type === 'FAX' && (
                    <Icons.Fax className={cx('card-wrapper__left-contact-wrapper-icon')} style={{ fill: iconColor }} data-is-mobile={isMobileMode} />
                  )}
                </div>
                <p className={cx('card-wrapper__left-contact-wrapper-content')}>{phone.number}</p>
              </div>
            ))}
          </div>

          <div className={cx('card-wrapper__left-contact')} data-is-mobile={isMobileMode}>
            {emailList.map((email, index) => (
              <div className={cx('card-wrapper__left-contact-wrapper')} key={index} data-is-mobile={isMobileMode}>
                <div>
                  <Icons.Email className={cx('card-wrapper__left-contact-wrapper-icon')} style={{ fill: iconColor }} data-is-mobile={isMobileMode} />
                </div>
                <p className={cx('card-wrapper__left-contact-wrapper-content')}>{email}</p>
              </div>
            ))}
          </div>

          <div className={cx('card-wrapper__left-contact')} data-is-mobile={isMobileMode}>
            {webUrlList.map((webUrl, index) => (
              <div className={cx('card-wrapper__left-contact-wrapper')} key={index} data-is-mobile={isMobileMode}>
                <div>
                  <Icons.Web className={cx('card-wrapper__left-contact-wrapper-icon')} style={{ fill: iconColor }} data-is-mobile={isMobileMode} />
                </div>
                <p className={cx('card-wrapper__left-contact-wrapper-content')}>{webUrl}</p>
              </div>
            ))}
          </div>

          <div className={cx('card-wrapper__left-address')} data-is-mobile={isMobileMode}>
            <div className={cx('card-wrapper__left-address-icon')}>
              {(address || chineseAddress) && (
                <Icons.Address className={cx('card-wrapper__left-address-icon-image')} style={{ fill: iconColor }} data-is-mobile={isMobileMode} />
              )}
            </div>
            <div className={cx('card-wrapper__left-address-wrapper')} data-is-mobile={isMobileMode}>
              <p className={cx('card-wrapper__left-address-wrapper-text')} data-is-mobile={isMobileMode}>
                {address}
              </p>
              <p className={cx('card-wrapper__left-address-wrapper-text')}>{chineseAddress}</p>
            </div>
          </div>

          {wechat.value && (
            <div className={cx('card-wrapper__left-wechat')} data-is-mobile={isMobileMode}>
              <div className={cx('card-wrapper__left-wechat-icon')} data-is-mobile={isMobileMode}>
                <Icons.Wechat className={cx('card-wrapper__left-wechat-icon-image')} data-is-mobile={isMobileMode} />
              </div>
              <p className={cx('card-wrapper__left-wechat-text')} data-is-mobile={isMobileMode}>
                {wechat.value}
              </p>
            </div>
          )}
        </div>
        {companyLogo?.fileUrl && (
          <div className={cx('card-wrapper__right')} data-is-mobile={isMobileMode}>
            <div className={cx('card-wrapper__right-logo')} data-is-mobile={isMobileMode}>
              <img className={cx('card-wrapper__right-logo-image')} src={companyLogo?.fileUrl} data-is-mobile={isMobileMode} />
            </div>

            <div className={cx('card-wrapper__right-bottom')}>
              <div className={cx('card-wrapper__right-bottom-qrcode')} style={{ borderColor: buttonColor }} data-is-mobile={isMobileMode}>
                <Icons.QrCode className={cx('card-wrapper__right-bottom-qrcode-image')} fill={buttonColor} />
              </div>
              <Icons.AddContact className={cx('card-wrapper__right-bottom-contact')} style={{ fill: buttonColor }} data-is-mobile={isMobileMode} />
            </div>
          </div>
        )}
      </div>
      <div className={cx('card-mobile-contact')} style={{ backgroundColor: buttonColor }} data-is-mobile={isMobileMode}>
        <Icons.Contact className={cx('card-mobile-contact-icon')} />
        <p className={cx('card-mobile-contact-text')}>Add to Contact 加入聯絡人</p>
      </div>
    </div>
  )
}

Card.propTypes = propTypes

export default Card
