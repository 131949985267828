import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
}

function SectionTitle(props) {
  const { className, title } = props

  return <p className={cx('section-title', className)}>{title}</p>
}

SectionTitle.propTypes = propTypes

export default SectionTitle
