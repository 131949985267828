import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext } from 'formik'

// Components
import Button from '@/basicComponents/Button'
import Form from '@/basicComponents/Form'
import RemoveIcon from '@/assets/svg/remove.svg'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
}

function SocialItem(props) {
  const { icon, title, name, placeholder } = props

  const [isEditing, setIsEditing] = useState(false)

  const { values, setFieldValue } = useFormikContext()

  const currentValue = values.social[name]

  const onSubmitClick = () => {
    setIsEditing(false)

    setFieldValue(`social[${name}].isSubmitted`, true)
  }

  const onRemoveClick = () => {
    setIsEditing(false)

    setFieldValue(`social[${name}]`, { value: '', isSubmitted: false })
  }

  return (
    <div className={cx('social-item')}>
      <div className={cx('social-item__left')}>
        <img className={cx('social-item__left-icon')} src={icon} />
        <p className={cx('social-item__left-title')}>{title}</p>
      </div>
      <div className={cx('social-item__right')}>
        {!isEditing && !currentValue.isSubmitted && (
          <Button size='xs' onClick={() => setIsEditing(true)}>
            Link
          </Button>
        )}
        {isEditing && (
          <>
            <Form.InputField
              inputClassName={cx('social-item__right-input')}
              name={`social.${name}.value`}
              placeholder={placeholder}
              autoFocus
              size='xs'
            />
            <p className={cx('social-item__right-submit')} onClick={onSubmitClick}>
              Submit
            </p>
          </>
        )}
        {currentValue.isSubmitted && <p className={cx('social-item__right-text')}>{currentValue.value}</p>}
        {(isEditing || currentValue.isSubmitted) && <img className={cx('social-item__right-remove')} src={RemoveIcon} onClick={onRemoveClick} />}
      </div>
    </div>
  )
}

SocialItem.propTypes = propTypes

export default SocialItem
