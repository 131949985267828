import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// components
import { components } from 'react-select'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  selectProps: PropTypes.object,
}

function Control(props) {
  const { className, children, selectProps, ...restProps } = props

  const { icon } = selectProps

  return (
    <components.Control {...restProps}>
      <img className={cx('icon')} src={icon} />
      {children}
    </components.Control>
  )
}

Control.propTypes = propTypes

export default Control
