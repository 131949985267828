import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  icon: PropTypes.string,
  activedIcon: PropTypes.string,
  isActived: PropTypes.bool,
  handleDeviceClick: PropTypes.func,
  title: PropTypes.string,
}

function DeviceItem(props) {
  const { icon, activedIcon, isActived, handleDeviceClick, title } = props

  return (
    <div className={cx('device-item')} onClick={handleDeviceClick} data-is-actived={isActived}>
      {isActived && <img className={cx('device-item-icon')} src={activedIcon} />}
      {!isActived && <img className={cx('device-item-icon')} src={icon} />}
      <p className={cx('device-item-text')} data-is-actived={isActived}>
        {title}
      </p>
    </div>
  )
}

DeviceItem.propTypes = propTypes

export default DeviceItem
