import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import OverlayImage from '@/assets/images/overlay.png'

// Lib MISC

import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  setIsClosedOverlay: PropTypes.func,
}

export const defaultProps = {}

const computedScaleRatio = ({ screenWidth, baseWidth, baseScale }) => {
  const ratio = baseWidth / screenWidth
  const scale = baseScale * ratio

  return scale
}

function Overlay(props) {
  const { setIsClosedOverlay } = props

  const [currentStyle, setCurrentStyle] = useState({})

  useEffect(() => {
    const handleWindowWidth = () => {
      // 因為沒有設 meta viewport，media query 的 width 會不精準
      // 所以直接抓 screen.width 判斷裝置寬度

      // 因為手機會多底部的 toolbar，height 需要扣掉
      const vh = window.innerHeight * 0.01
      const vHeight = `${vh}px`

      const isTablet = screen.width <= 1024 && screen.width >= 768
      const isMobile = screen.width < 768

      if (isTablet) {
        const scale = computedScaleRatio({ screenWidth: screen.width, baseWidth: 768, baseScale: 1.28 })

        setCurrentStyle({ transform: `scale(${scale}, ${scale})`, maxWidth: 440, padding: '40px 40px 24px 40px', '--vh': vHeight })
      } else if (isMobile) {
        const scale = computedScaleRatio({ screenWidth: screen.width, baseWidth: 360, baseScale: 2.7 })

        setCurrentStyle({ transform: `scale(${scale}, ${scale})`, maxWidth: 324, '--vh': vHeight })
      } else {
        setCurrentStyle({}) // tablet 以上就不另外做 scale
      }
    }

    handleWindowWidth()

    window.addEventListener('resize', handleWindowWidth)

    return () => {
      window.removeEventListener('resize', handleWindowWidth)
    }
  }, [])

  return (
    <div className={cx('overlay')}>
      <div className={cx('overlay-wrapper')} id='overlayWrapper' style={currentStyle}>
        <div className={cx('overlay-wrapper__image')}>
          <img className={cx('overlay-wrapper__image-item')} src={OverlayImage} />
        </div>
        <div className={cx('overlay-wrapper__content')}>
          <div className={cx('overlay-wrapper__content-wrapper')}>
            <p className={cx('overlay-wrapper__content-wrapper-title')}>Enjoy best experience on your desktop</p>
            <p className={cx('overlay-wrapper__content-wrapper-text')}>
              Create your professional digital name card on your desktop machine to enjoy the best experience.
            </p>
            <div className={cx('overlay-wrapper__content-wrapper-link')}>
              <span className={cx('overlay-wrapper__content-wrapper-link-text')}>Please visit </span>
              <a href='https://www.re.cards' rel='noreferrer noopener' target='_blank'>
                <span className={cx('overlay-wrapper__content-wrapper-link-text', 'highlight')}>c.re.cards</span>
              </a>
            </div>
          </div>
          <p className={cx('overlay-wrapper__content-close')} onClick={() => setIsClosedOverlay(true)}>
            Bring me to create card website anyway
          </p>
        </div>
      </div>
    </div>
  )
}

Overlay.propTypes = propTypes
Overlay.defaultProps = defaultProps

export default Overlay
