import React from 'react'
import classnames from 'classnames/bind'

// Components
import Logo from '@/assets/images/recard-logo.png'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

function Header() {
  return (
    <div className={cx('header')}>
      <div className={cx('header-wrapper')}>
        <div className={cx('header-wrapper__left')}>
          <img className={cx('header-wrapper__left-logo')} src={Logo} />
        </div>
      </div>
    </div>
  )
}

Header.propTypes = propTypes

export default Header
