import { cloneDeep } from 'lodash'

const formikConfig = (props) => {
  const { validationSchema, createCard, currentPage } = props

  return {
    validationSchema,

    initialValues: {
      personal: {
        firstName: '',
        lastName: '',
        chineseName: '',
        title: '',
        chineseTitle: '',
        company: '',
        companyLogo: null,
        photo: null,
      },
      contact: {
        emailList: [],
        phoneList: [],
        webUrlList: [],
        address: '',
        chineseAddress: '',
      },
      social: {
        linkedIn: { value: '', isSubmitted: false },
        facebook: { value: '', isSubmitted: false },
        twitter: { value: '', isSubmitted: false },
        wechat: { value: '', isSubmitted: false },
        whatsapp: { value: '', isSubmitted: false },
      },
      cardStyle: {
        backgroundType: 'solidColor',
        headerStartBackgroundColor: '#000623',
        headerEndBackgroundColor: '#ee232a',
        footerBackgroundColor: '#f2f2f2',
        cardBackgroundColor: '#ffffff',
        textColor: '#000623',
        iconColor: '#7f7f7f',
        buttonColor: '#7f7f7f',
        headerImage: null,
        mobileHeaderImage: null,
        footerImage: null,
        mobileFooterImage: null,
      },
    },

    onSubmit(values) {
      const isLastPage = currentPage === 3 // 目前有 4 頁

      if (isLastPage) {
        const cloneData = cloneDeep(values)
        const { personal, contact, social, cardStyle } = cloneData

        // 改成 BE schema
        const submitData = {
          nameCard: {
            photo: personal.photo?.file,
            name: {
              firstName: personal.firstName,
              lastName: personal.lastName,
              nameZh: personal.chineseName,
            },
            organization: personal.company,
            logo: personal.companyLogo?.file,
            title: {
              en: personal.title,
              zh: personal.chineseTitle,
            },
            phones: contact.phoneList.map((phone) => ({ value: phone.number, type: phone.type })),
            emails: contact.emailList,
            urls: contact.webUrlList,
            address: {
              en: contact.address,
              zh: contact.chineseAddress,
            },
            socials: {
              facebook: social.facebook.value,
              linkedIn: social.linkedIn.value,
              twitter: social.twitter.value,
              whatsapp: social.whatsapp.value,
              wechat: social.wechat.value,
            },
          },
          style: {
            headerBackgroundStartColor: cardStyle.headerStartBackgroundColor,
            headerBackgroundEndColor: cardStyle.headerEndBackgroundColor,
            backgroundColor: cardStyle.footerBackgroundColor,
            cardBackgroundColor: cardStyle.cardBackgroundColor,
            textColor: cardStyle.textColor,
            iconColor: cardStyle.iconColor,
            buttonColor: cardStyle.buttonColor,
            headerImage: cardStyle.headerImage?.file,
            mobileHeaderImage: cardStyle.mobileHeaderImage?.file,
            footerImage: cardStyle.footerImage?.file,
            mobileFooterImage: cardStyle.mobileFooterImage?.file,
          },
        }

        createCard({ variables: submitData })
      }
    },
  }
}

export default formikConfig
