import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { connect } from 'formik'

// Components
import InputField from './InputField'
import TextareaField from './TextareaField'
import SelectField from './SelectField'
import ColorInputField from './ColorInputField'
import FileInputField from './FileInputField'
import CheckboxField from './CheckboxField'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  forwardRef: PropTypes.any,
  formik: PropTypes.object,
}

function Form(props) {
  const { className, forwardRef, formik, ...restProps } = props

  return <form className={cx('form', className)} ref={forwardRef} onReset={formik.handleReset} onSubmit={formik.handleSubmit} {...restProps} />
}

Form.propTypes = propTypes

const ConnectedForm = connect(Form)
const ConnectedFormWithRef = forwardRef((props, ref) => <ConnectedForm {...props} forwardRef={ref} />)

ConnectedFormWithRef.InputField = InputField
ConnectedFormWithRef.TextareaField = TextareaField
ConnectedFormWithRef.SelectField = SelectField
ConnectedFormWithRef.ColorInputField = ColorInputField
ConnectedFormWithRef.FileInputField = FileInputField
ConnectedFormWithRef.CheckboxField = CheckboxField

export default ConnectedFormWithRef
