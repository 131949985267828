import React, { useState } from 'react'
import classnames from 'classnames/bind'

// Components
import SectionTitle from '../SectionTitle'
import Form from '@/basicComponents/Form'
import TabItem from './components/TabItem'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

const BACKGROUND_TYPE = {
  SOLID_COLOR: 'solidColor',
  IMAGE: 'image',
}

function CardStyle(props) {
  const [backgroundType, setBackgroundType] = useState(BACKGROUND_TYPE.SOLID_COLOR)

  return (
    <div className={cx('card-style')}>
      <SectionTitle title='BACKGROUND' />

      <div className={cx('card-style-tab')}>
        <TabItem text='Solid Color' type={BACKGROUND_TYPE.SOLID_COLOR} backgroundType={backgroundType} setBackgroundType={setBackgroundType} />
        <TabItem text='Fill Image' type={BACKGROUND_TYPE.IMAGE} backgroundType={backgroundType} setBackgroundType={setBackgroundType} />
      </div>

      {backgroundType === BACKGROUND_TYPE.SOLID_COLOR && (
        <>
          <div className={cx('card-style-row')}>
            <p className={cx('card-style-row-title')}>Header background color 1</p>
            <Form.ColorInputField name='cardStyle.headerStartBackgroundColor' />
          </div>

          <div className={cx('card-style-row')}>
            <p className={cx('card-style-row-title')}>Header background color 2</p>
            <Form.ColorInputField name='cardStyle.headerEndBackgroundColor' />
          </div>

          <div className={cx('card-style-row')}>
            <p className={cx('card-style-row-title')}>Footer background color</p>
            <Form.ColorInputField name='cardStyle.footerBackgroundColor' />
          </div>
        </>
      )}

      {backgroundType === BACKGROUND_TYPE.IMAGE && (
        <>
          <div className={cx('card-style-column')}>
            <p className={cx('card-style-column-title')}>Header of desktop</p>
            <Form.FileInputField name='cardStyle.headerImage' placeHolder='Upload header of desktop' />
          </div>

          <div className={cx('card-style-column')}>
            <p className={cx('card-style-column-title')}>Header of mobile</p>
            <Form.FileInputField name='cardStyle.mobileHeaderImage' placeHolder='Upload header of mobile' />
          </div>

          <div className={cx('card-style-column')}>
            <p className={cx('card-style-column-title')}>Footer of desktop</p>
            <Form.FileInputField name='cardStyle.footerImage' placeHolder='Upload footer of desktop' />
          </div>

          <div className={cx('card-style-column')}>
            <p className={cx('card-style-column-title')}>Footer of mobile</p>
            <Form.FileInputField name='cardStyle.mobileFooterImage' placeHolder='Upload footer of mobile' />
          </div>
        </>
      )}

      <SectionTitle title='CARD STYLE' className={cx('card-style-section-title')} />

      <div className={cx('card-style-row')}>
        <p className={cx('card-style-row-title')}>Card background color</p>
        <Form.ColorInputField name='cardStyle.cardBackgroundColor' />
      </div>

      <div className={cx('card-style-row')}>
        <p className={cx('card-style-row-title')}>Text color</p>
        <Form.ColorInputField name='cardStyle.textColor' />
      </div>

      <div className={cx('card-style-row')}>
        <p className={cx('card-style-row-title')}>Icon color</p>
        <Form.ColorInputField name='cardStyle.iconColor' />
      </div>

      <div className={cx('card-style-row')}>
        <p className={cx('card-style-row-title')}>Button color</p>
        <Form.ColorInputField name='cardStyle.buttonColor' />
      </div>
    </div>
  )
}

CardStyle.propTypes = propTypes

export default CardStyle
