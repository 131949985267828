import classnames from 'classnames/bind'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

function Footer() {
  return (
    <div className={cx('footer')}>
      <div className={cx('footer-wrap')}>
        <div className={cx('footer-wrap-left')}>
          <p className={cx('footer-wrap-left-text')}>Copyright © 2021 RedSo. All Rights Reserved.</p>
        </div>
        <div className={cx('footer-wrap-right')}>
          <p className={cx('footer-wrap-right-text')}>Terms of Use</p>
          <p className={cx('footer-wrap-right-text')}>Privacy Policy</p>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = propTypes

export default Footer
