import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.number,
}

const defaultProps = {
  title: '',
  isRequired: false,
}

function FormItem(props) {
  const { children, title, isRequired, width } = props

  return (
    <div className={cx('form-item')} style={{ width }}>
      {title && (
        <div className={cx('form-item-title')}>
          <p className={cx('form-item-title-text')}> {title}</p>
          {isRequired && <div className={cx('form-item-title-required')}>*</div>}
        </div>
      )}
      {children}
    </div>
  )
}

FormItem.propTypes = propTypes
FormItem.defaultProps = defaultProps

export default FormItem
