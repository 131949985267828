import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import Header from '@/basicComponents/Header'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  classname: PropTypes.string,
  children: PropTypes.object,
}
function Layout(props) {
  const { classname, children } = props

  return (
    <div className={cx('layout', classname)}>
      <Header />
      {children}
    </div>
  )
}

Layout.propTypes = propTypes

export default Layout
