import React, { useState, useEffect } from 'react'
import classnames from 'classnames/bind'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

// Components
import CreateFormik from './components/CreateFormik'
import Display from './components/Display'
import Action from './components/Action'
import Overlay from './components/Overlay'

// Lib MISC
import { useMobileLayout } from '@/computedValues/useMobileLayout'
import validationSchema from './validationSchema'
import { DEVICE_MODE } from '@/constants/deviceMode'

// gql
import { CREATE_CARD } from './ggl'

import styles from './style.module.scss'

const cx = classnames.bind(styles)

function Create() {
  const [currentPage, setCurrentPage] = useState(0)
  const [currentDevice, setCurrentDevice] = useState(DEVICE_MODE.DESKTOP)
  const [isClosedOverlay, setIsClosedOverlay] = useState(false)

  const history = useHistory()

  const { isMoblieLayout } = useMobileLayout()
  const [createCard, { data = {}, loading: isLoading }] = useMutation(CREATE_CARD)
  const isDesktopMode = currentDevice === DEVICE_MODE.DESKTOP
  const isMobileMode = currentDevice === DEVICE_MODE.MOBILE

  const isSubmmittedSuccessfully = !isEmpty(data)

  // 進入 create
  useEffect(() => {
    history.replace('/?page=step1')
  }, [history])

  // submit 成功
  useEffect(() => {
    if (isSubmmittedSuccessfully) history.replace('/?page=created')
  }, [isSubmmittedSuccessfully, history])

  const handleDeviceClick = (mode) => {
    setCurrentDevice(mode)
  }

  return (
    <div className={cx('create')}>
      <CreateFormik validationSchema={validationSchema[currentPage]} currentPage={currentPage} createCard={createCard} history={history}>
        <Display isDesktopMode={isDesktopMode} isMobileMode={isMobileMode} handleDeviceClick={handleDeviceClick} />
        <Action
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={data}
          isLoading={isLoading}
          isSubmmittedSuccessfully={isSubmmittedSuccessfully}
        />
        {isMoblieLayout && !isClosedOverlay && <Overlay setIsClosedOverlay={setIsClosedOverlay} />}
      </CreateFormik>
    </div>
  )
}

export default Create
