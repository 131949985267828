import React from 'react'
import classnames from 'classnames/bind'
import styles from './style.module.scss'

// Components
import Layout from '@/basicComponents/Layout'
import Create from './components/Create'

// Lib MISC

const cx = classnames.bind(styles)

function Home() {
  return (
    <Layout className={cx('home')}>
      <Create />
    </Layout>
  )
}

export default Home
