// import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import SectionTitle from '../SectionTitle'
import PhotoUpload from './components/PhotoUpload'
import FormItem from './components/FormItem'
import Form from '@/basicComponents/Form'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

function PersonalInfo(props) {
  return (
    <div className={cx('personal-info')}>
      <SectionTitle title='PERSONAL INFORMATION' />
      <PhotoUpload />

      <div className={cx('personal-info__form')}>
        <div className={cx('personal-info__form-row')}>
          <FormItem title='First name' width={292} isRequired>
            <Form.InputField className={cx('personal-info__form-input')} name='personal.firstName' placeholder='First name' />
          </FormItem>
          <FormItem title='Last name' width={292} isRequired>
            <Form.InputField className={cx('personal-info__form-input')} name='personal.lastName' placeholder='Last name' />
          </FormItem>
        </div>

        <div className={cx('personal-info__form-row')}>
          <FormItem title='Full name in Chinese' width={600}>
            <Form.InputField className={cx('personal-info__form-input')} name='personal.chineseName' placeholder='Full name in Chinese' />
          </FormItem>
        </div>

        <div className={cx('personal-info__form-row')}>
          <FormItem title='Title' width={292} isRequired>
            <Form.InputField className={cx('personal-info__form-input')} name='personal.title' placeholder='Title' />
          </FormItem>
          <FormItem title='Title in Chinese' width={292}>
            <Form.InputField className={cx('personal-info__form-input')} name='personal.chineseTitle' placeholder='Title in Chinese' />
          </FormItem>
        </div>

        <div className={cx('personal-info__form-row')}>
          <FormItem title='Company' width={600} isRequired>
            <Form.InputField className={cx('personal-info__form-input')} name='personal.company' placeholder='Company' />
          </FormItem>
        </div>

        <div className={cx('personal-info__form-row')}>
          <FormItem title='Company logo' width={600} isRequired>
            <Form.FileInputField name='personal.companyLogo' placeHolder='Upload company logo' />
          </FormItem>
        </div>
      </div>
    </div>
  )
}

PersonalInfo.propTypes = propTypes

export default PersonalInfo
