import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import ColorPicker from 'rc-color-picker'
import 'rc-color-picker/assets/index.css'

// Components

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
}

const defautlProps = {
  onChange: () => {},
}

function ColorInput(props) {
  const { onChange: onChangeProps, defaultValue } = props

  const defaultColor = defaultValue || '#000000'

  const [currentColor, setCurrentColor] = useState(defaultColor)

  const onColorChange = ({ color }) => {
    setCurrentColor(color)
    onChangeProps(color)
  }

  return (
    <div className={cx('color-input')}>
      <ColorPicker color={currentColor} onChange={onColorChange} enableAlpha={false}>
        <div className={cx('color-input-display')} style={{ backgroundColor: currentColor }} />
      </ColorPicker>
    </div>
  )
}

ColorInput.propTypes = propTypes
ColorInput.defautlProps = defautlProps

export default ColorInput
