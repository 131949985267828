import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

const defaultProps = {}

function Checkbox(props) {
  const { className, checked, onChange } = props

  return (
    <>
      <label className={cx('checkbox', className)}>
        <input className={cx('checkbox-input')} type='checkbox' checked={checked} onChange={onChange} />
        <span className={cx('checkbox-icon')} data-is-checked={checked} />
      </label>
    </>
  )
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox
