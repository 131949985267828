import * as Yup from 'yup'

const validationSchema = [
  // Personal
  Yup.object().shape({
    personal: Yup.object().shape({
      firstName: Yup.string().required('Please enter your first name'),
      lastName: Yup.string().required('Please enter your last name'),
      title: Yup.string().required('Please enter your title'),
      company: Yup.string().required('Please enter your company'),
      companyLogo: Yup.object().nullable().required('Please select your company logo'),
    }),
  }),
  // Contact
  Yup.object().shape({
    contact: Yup.object().shape({
      emailList: Yup.array().of(
        Yup.string()
          .required('Please enter your email')
          .matches(/\S+@\S+\.\S+/, 'Please enter a valid email address'),
      ),
      webUrlList: Yup.array().of(
        Yup.string()
          .required('Please enter your url')
          .matches(/^https?:\/\//, 'Please enter a valid url'),
      ),
      phoneList: Yup.array().of(
        Yup.object().shape({
          number: Yup.string().required('Please enter your phone'),
        }),
      ),
    }),
  }),
  // Social
  Yup.object().shape({
    social: Yup.object().shape({}),
  }),
  // Card style
  Yup.object().shape({
    cardStyle: Yup.object().shape({}),
  }),
]

export default validationSchema
