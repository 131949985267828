import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext } from 'formik'
import { isEmpty, get } from 'lodash'

// Components
import Button from '@/basicComponents/Button'
import RemoveIcon from '@/assets/svg/remove.svg'
import ErrorMessage from '../ErrorMessage'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  name: PropTypes.string,
  placeHolder: PropTypes.string,
}

function FileInputField(props) {
  const { name, placeHolder } = props

  const inputRef = useRef(null)

  const { values, setFieldValue } = useFormikContext()

  const filedValue = get(values, name)
  const hasFile = !isEmpty(filedValue)

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]

    const fileUrl = window.URL.createObjectURL(selectedFile)

    setFieldValue(name, { file: selectedFile, fileUrl })
  }

  const handleRemoveClick = () => {
    inputRef.current.value = null
    setFieldValue(name, {})
  }

  return (
    <div className={cx('file-input')}>
      <div className={cx('file-input-wrapper')}>
        <input className={cx('file-input-wrapper-element')} ref={inputRef} type='file' accept='image/*' onChange={handleFileChange} />
        {!hasFile && (
          <>
            <p className={cx('file-input-wrapper-placeholder')}>{placeHolder}</p>
            <Button size='xs' onClick={() => inputRef.current.click()}>
              Upload
            </Button>
          </>
        )}
        {hasFile && (
          <>
            <div className={cx('file-input-wrapper-upload')}>
              <p className={cx('file-input-wrapper-upload-name')}>{filedValue?.file.name}</p>
              <img className={cx('file-input-wrapper-upload-remove')} src={RemoveIcon} onClick={handleRemoveClick} />
            </div>
          </>
        )}
      </div>
      <ErrorMessage name={name} />
    </div>
  )
}

FileInputField.propTypes = propTypes

export default FileInputField
