import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  text: PropTypes.string,
  backgroundType: PropTypes.string,
  setBackgroundType: PropTypes.func,
  type: PropTypes.string,
}

function TabItem(props) {
  const { text, backgroundType, setBackgroundType, type } = props

  const isSelected = backgroundType === type

  const onTabItemClick = () => {
    setBackgroundType(type)
  }

  return (
    <div className={cx('tab-item')} onClick={onTabItemClick} data-is-selected={isSelected}>
      <p className={cx('tab-item-text')} data-is-selected={isSelected}>
        {text}
      </p>
    </div>
  )
}

TabItem.propTypes = propTypes

export default TabItem
