import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import Button from '@/basicComponents/Button'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
  bodyStyle: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
}

const defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  hasHeader: true,
  hasFooter: true,
}

function Modal(props) {
  const { className, children, bodyStyle, title, content, cancelLabel, confirmLabel, onConfirm, onCancel, hasHeader, hasFooter } = props

  return (
    <div className={cx('modal')}>
      <div className={cx('modal-wrapper', className)}>
        {hasHeader && (
          <div className={cx('modal-wrapper__header')}>
            <p className={cx('modal-wrapper__header-title')}>{title}</p>
          </div>
        )}
        <div className={cx('modal-wrapper__body')} style={bodyStyle}>
          <p className={cx('modal-wrapper__body-content')}>{content}</p>
          {children}
        </div>
        {hasFooter && (
          <div className={cx('modal-wrapper__footer')}>
            <Button className={cx('modal-wrapper__footer-button')} onClick={onCancel} isFilled={false}>
              {cancelLabel}
            </Button>
            <Button className={cx('modal-wrapper__footer-button')} onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
