import { useState, useEffect } from 'react'

const MAX_MOBILE_WIDTH = 1024

export function useMobileLayout() {
  const [isMoblieLayout, setIsMoblieLayout] = useState(false)

  useEffect(() => {
    const handleWindowWidth = () => {
      if (window.innerWidth <= MAX_MOBILE_WIDTH) {
        setIsMoblieLayout(true)
      } else {
        setIsMoblieLayout(false)
      }
    }

    handleWindowWidth()

    window.addEventListener('resize', handleWindowWidth)

    return () => {
      window.removeEventListener('resize', handleWindowWidth)
    }
  }, [setIsMoblieLayout])

  return { isMoblieLayout }
}
