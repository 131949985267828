// import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Components
import SectionTitle from '../SectionTitle'
import SocialItem from './components/SocialItem'
import LinkedinIcon from '@/assets/svg/social-linkedin.svg'
import FacebookIcon from '@/assets/svg/social-facebook.svg'
import TwitterIcon from '@/assets/svg/social-twitter.svg'
import WechatIcon from '@/assets/svg/social-wechat.svg'
import WhatsappIcon from '@/assets/svg/social-whatsapp.svg'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

function SocialLink(props) {
  return (
    <div className={cx('social-link')}>
      <SectionTitle className={cx('social-link-title')} title='SOCIAL LINK' />
      <SocialItem icon={LinkedinIcon} title='LinkedIn' name='linkedIn' placeholder='Enter URL' />
      <SocialItem icon={FacebookIcon} title='Facebook' name='facebook' placeholder='Enter Username' />
      <SocialItem icon={TwitterIcon} title='Twitter' name='twitter' placeholder='Enter Username' />
      <SocialItem icon={WechatIcon} title='WeChat' name='wechat' placeholder='Enter WeChat ID' />
      <SocialItem icon={WhatsappIcon} title='WhatsApp' name='whatsapp' placeholder='Enter WhatsApp ID' />
    </div>
  )
}

SocialLink.propTypes = propTypes

export default SocialLink
