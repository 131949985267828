import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// components
import { components } from 'react-select'
import DropdownIcon from '@/assets/svg/arrow-down.svg'
import DropdownUpIcon from '@/assets/svg/arrow-up.svg'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  selectProps: PropTypes.object,
}

function DropdownIndicator(props) {
  const { selectProps } = props
  const { menuIsOpen } = selectProps

  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? <img className={cx('icon')} src={DropdownUpIcon} /> : <img className={cx('icon')} src={DropdownIcon} />}
    </components.DropdownIndicator>
  )
}

DropdownIndicator.propTypes = propTypes

export default DropdownIndicator
