import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { useFormikContext, useField } from 'formik'

// Components
import Checkbox from '@/basicComponents/Checkbox'
// import ErrorMessage from '../ErrorMessage'

// Variables / Functions
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export const defaultProps = { onChange: () => {} }

function CheckboxField(props) {
  const { className, name, onChange: onChangeProps, ...restProps } = props
  const [field] = useField(props)
  const { setFieldValue } = useFormikContext()

  const onChange = (event) => {
    setFieldValue(field.name, !field.value)
    onChangeProps()
  }

  return (
    <div className={cx('checkbox-field-wrapper', className)}>
      <Checkbox {...restProps} onChange={onChange} checked={field.value} name={name} />
      {/* <ErrorMessage name={name} /> */}
    </div>
  )
}

CheckboxField.propTypes = propTypes
CheckboxField.defaultProps = defaultProps

export default CheckboxField
