import React from 'react'
// import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// components
import Select from 'react-select'
import Control from './components/Control'
import DropdownIndicator from './components/DropdownIndicator'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

const selectStyles = {
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      cursor: 'pointer',
      boxShadow: 'none',
      borderRadius: 8,
      border: 'solid 1px #e4e4e4',
      height: 64,
      paddingLeft: 12,

      '&:hover': {
        border: 'solid 1px #ff9933',
        boxShadow: 'none',
      },
    }
  },

  option: (styles, { isSelected, isFocused }) => {
    return {
      ...styles,
      fontSize: '20px',
      backgroundColor: isFocused ? '#ffe9d2' : '#ffffff',
      color: '#000000',
      cursor: 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? '#ffe9d2' : '#ffffff',
      },
    }
  },

  menu: (styles) => ({ ...styles }),

  valueContainer: (styles) => ({ ...styles, padding: '2px 6px' }),

  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),

  singleValue: (styles) => ({
    ...styles,
    color: '#000',
    fontSize: '20px',
  }),

  input: (styles) => {
    const inputElement = '& > div > input'

    return {
      ...styles,

      [inputElement]: {
        color: 'transparent !important',
      },
    }
  },
}

function SelectComponent(props) {
  const { ...restProps } = props

  return <Select className={cx('select')} styles={selectStyles} components={{ Control, DropdownIndicator }} {...restProps} />
}

SelectComponent.propTypes = propTypes

export default SelectComponent
