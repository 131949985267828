import classnames from 'classnames/bind'

// Components
import PreviewIcon from '@/assets/svg/preview.svg'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {}

function Empty(props) {
  return (
    <div className={cx('empty')}>
      <img className={cx('empty-icon')} src={PreviewIcon} />
      <p className={cx('empty-text')}>Please fill in your personal information on the right first.</p>
    </div>
  )
}

Empty.propTypes = propTypes

export default Empty
