import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'dark']),
}

const defaultProps = {
  type: 'primary',
}

function Textarea(props) {
  const { className, type, ...restProps } = props

  return <textarea className={cx('textarea', className)} data-type={type} {...restProps} />
}

Textarea.propTypes = propTypes
Textarea.defaultProps = defaultProps

export default Textarea
