import PropTypes from 'prop-types'
import classnames from 'classnames/bind'

// Lib MISC
import styles from './style.module.scss'

const cx = classnames.bind(styles)

export const propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'danger']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  isFilled: PropTypes.bool,
  elementType: PropTypes.string,
}

const defaultProps = {
  type: 'primary',
  size: 'sm',
  isFilled: true,
  elementType: 'button',
}

function Button(props) {
  const { className, children, type, size, isFilled, elementType, ...restProps } = props

  return (
    <button className={cx('button', className)} data-type={type} data-size={size} data-is-filled={isFilled} type={elementType} {...restProps}>
      {children}
    </button>
  )
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
